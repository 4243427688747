 .slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
  }
  
   .slideWrapper {
    display: flex;
    justify-content: center;
  }
  
   .prevArrow {
    position: absolute;
    top: 40%;
    left: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 24px;
  }
  
   .nextArrow {
    position: absolute;
    top: 40%;
    right: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 24px;
  }

   .slick-slide img {
      width: 400px !important;
      height: 400px !important;
  }

  @media (max-width: 1040px) {
    .slick-slide img {
      width: 300px !important;
      height: 400px !important;
    }
}

@media (max-width: 820px) {
  .slick-slide img {
    width: 400px !important;
    height: 400px !important;
  }
}
@media (max-width: 490px) {
  .slick-slide img {
    width: 300px !important;
    height: 250px !important;
  }
}