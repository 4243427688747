.Dec6_Industries_BgImage_Block_Al {
    width: 100%;
    height: 500px;
    /* background-image: url('../Images/Indus.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: right;
    background-position: bottom;
}

.Dec6_PYG_Grid {
    max-width: 100%;
}
.Dec6_PYG_Grid1 {
    max-width: 100%;
}
/* big-landscape tablets, low resolution laptop and desktop */
@media (max-width: 1200px) {
  
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    
}

@media (max-width: 450px) {
   
}