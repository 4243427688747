@import "../../../MainCSS.css";


.D6_ADS_Cont {
    margin-top: 50px;
    margin-bottom: 40px;
}
.D6_SL_CU_Map_Grid {
    color: var(--bcolor);
}

.D6_SL_CU_Map_Grid_Div {
    max-width: 200px;
    min-height: 80px;
    margin: 20px auto;
}
.D6_SL_CU_Map_Grid_DivT {
    height: 70px;
}

.D6_SL_CU_Map {
    max-width: 100%;
    margin: auto;
    margin-top: 10px;
}

.D6_SL_CU_Map_Grid_Div_FU {
    height: 50px;
}

.D6_SL_CU_Map_Grid_H {
    font-size: 20px;
    font-weight: 700;
    color: var(--bcolor);
    padding-bottom: 5px;
}

.D6_SL_CU_Map_Grid_Add {
    font-size: 15px;
    font-weight: lighter;
}
.D6_SL_CU_Map_Grid_Add2 {
    padding-top: 5px;
    font-size: 15px;
    color: var(--bcolor);
    font-weight: lighter;
}

.D6_SL_CU_Map_Grid3_Detail a{
    text-decoration: none;
    color: var(--bcolor);
}

.D6_SL_CU_Map_Grid3_Detail a:hover{
    color: var(--ycolor);
}

.D6_SL_CU_Map_Link a{
    text-decoration: none;
    color: var(--bcolor);
    padding-bottom: 10px;
}

.D6_SL_CU_Map_Link a:hover{
    color: var(--ycolor);
}
.D6_SL_CU_Map_Icon {
    font-size: 22px;
    padding-right: 20px;
}

.D6_SL_CU_Map_Grid3_Detail_Icon {
    font-size: 15px;
    color: var(--bcolor);
    color: var(--bcolor);
    padding-right: 10px;
}
.D6_SL_CU_Map_Grid3_Detail .fa-envelope {
    font-size: 15px;
    color: var(--bcolor);
    color: var(--bcolor);
    padding-right: 10px;
}

.D6_SL_CU_Map_Grid3_Detail {
    font-size: 15px;
 /*   padding: 10px 0px; */
    padding-bottom: 10px;
}
