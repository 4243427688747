@import "../../../MainCSS.css";

.D6_SL_WS_Cont {
       margin-top: 30px,
   }
.D6_SL_WS_Grid1_Div {
       text-align: center;
       align-items: center;
       margin-bottom: 20px;
       color:var(--bcolor)
   }
.D6_SL_WS_Grid2_DivHI {
       margin-top: 0px;
   }
.D6_SL_WS_Grid1_Div_H1{
    font-size: 44px !important ;
    font-weight: 300;
    color:var(--bcolor);
    line-height: 48px;
    display: block;
    margin:0 0 30px;
    text-align: center
    
     /* &span {
        font-size:36px;
        
    } */
   }
   .D6_SL_WS_Grid1_Div_H2{
    font-size: 36px !important ;
    font-weight: 300;
    color:var(--bcolor);
    line-height: 40px;
    display: block;
    margin:0 0 30px;
    text-align: center
    
     /* &span {
        font-size:36px;
        
    } */
   }
   .D6_SL_WS_Grid1_Div_H3{
    font-size: 32px !important ;
    font-weight: 300;
    color:var(--bcolor);
    line-height: 40px;
    display: block;
    margin:0 0 30px;
    text-align: center
    
     /* &span {
        font-size:36px;
        
    } */
   }
.D6_SL_WS_Grid2_container {
       width: 100%;
       display: flex;
       margin: 40px 0
      
   }
.D6_SL_WS_Grid2_Div {
       width: 96%;
       margin: 20px auto;
   
   }
.D6_SL_WS_Grid2_HDiv {
       width: 100%;
       border-bottom: 2px solid var(--ycolor);
       margin-bottom: 10px;
       display: flex
   }
   .D6_SL_WS_HIcon {
       display: inline-flex;
       width: 40px;
       height:40px;
   
   }
   
   .D6_SL_WS_Grid2_Div_H1  {
       font-size: 30px;
       font-weight: 300;
       color:var(--bcolor);
       line-height: 40px;
       padding-left: 20px;
       padding-bottom: 10px;
       display: inline-flex;
       text-align: left;
   }
   .D6_SL_WS_Grid2_Div_P {
       font-size: 18px !important;
       text-align: justify;
   }
   @media (max-width: 768px){
    .D6_SL_WS_Grid2_Div_P {
        font-size: 16px !important
    }
    .D6_SL_WS_Grid1_Div_H1{
        font-size: 30px !important ;
       
       }
       .D6_SL_WS_Grid1_Div_H1{
        font-size: 26px !important ;
        font-weight: 300;
        color:var(--bcolor);
        line-height: 40px;
        display: block;
        margin:0 0 30px;
        text-align: center
        
         /* &span {
            font-size:36px;
            
        } */
       }
       .D6_SL_WS_Grid1_Div_H2{
        font-size: 20px !important ;
        font-weight: 300;
        color:var(--bcolor);
        line-height: 30px;
        display: block;
        margin:0 0 30px;
        text-align: center
        
         /* &span {
            font-size:36px;
            
        } */
       }
   }
   .Dec6_Industries_B2B_Grid1{
    padding: 20px;
}
.mach_h{
    justify-content: center !important;
    font-size: 25px !important;
    padding-top: 40px !important;
    color: var(--blueColor) !important;
    font-weight: bold;
}
.card_color{
    background: var(--blueColor);
    color: var(--bcolor);
    height: 180px !important;
}
.card_text{
    color: var(--bcolor) !important; 
    padding-top: 5px;
    font-size: 1rem !important;
}
.Dec6_Industries_B2B_Cont_Paper_Box {
    text-align: center;
    padding-top: 20px;
 /*   transition: transform 1s;  */
}
.card_2{
    max-width: auto;
    height: 564px !important;
}
.chip_button{
    color: whitesmoke !important;
    background-color: var(--ocolor) !important;
    margin-left: 10px !important;
}
.chip_button_P1{
    margin-left: 10px !important;
    color: whitesmoke !important;
    background-color: var(--ocolor) !important;
}
.chip_button1{
    color: whitesmoke !important;
    background-color: var(--ocolor) !important;
}
.chip_button_P{
    margin-left: 10px !important;
    color: whitesmoke !important;
    background-color: var(--ocolor) !important;
}
.Dec6_Industries_B2B_Cont_1{
    padding-top: 5px;
}
.paper_home{
    background-color: var(--ocolor) !important;
    height: 150px;
    text-align: center ;
    cursor: pointer;
}
.Icon_home{
    color: white;
    font-size: 60px;
    /* padding-top: 10px; */

  cursor: pointer;
}
.grid_text{
    color: white;
    font-size: 17px !important;
    padding-top: 15px;
}
.Grid_Chip{
    margin-top: 50px !important;
    margin-bottom: 5px;
}
@media (max-width:600px) {
    .card_2{
        height: auto !important;
    }
    .card_color{
        height: auto !important;
    }
    .grid_text{
        color: white;
        font-size: 14px !important;
        padding-top: 15px;
    }
    .Grid_Chip{
        margin-top: 10px !important;
        margin-bottom: 5px;
    }
    .D6_SL_WS_Grid1_Div_H3{
        font-size: 18px !important ;
        font-weight: 300;
        color:var(--bcolor);
        line-height: 40px;
        display: block;
        margin:0 0 30px;
        text-align: center
        
         /* &span {
            font-size:36px;
            
        } */
       }
}