@import "../../../MainCSS.css";



.D6_DS_BG_Div {
  
    width: 100%;
    background-size: 100%;
    align-items: center;
    text-align: center;
}
.img{
      width:100%;
    }
  
  .D6_SL_WS_Grid2_HDivDu  {
    width: 100%;
    border-bottom: 2px solid var(--ocolor);
    margin-bottom: 10px;
    display: flex;
}
.Grid_Types{
  color: var(--bcolor);
}
.Grid_Types_h{
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.01rem !important;
  color: var(--bcolor);
}
.ul_dual{
align-items: center;
padding-left: 60px;
list-style-type: circle;

}
.li_dual{
  padding-top: 20px;
  /* font-size:0.9rem; */
  padding-bottom: 10px;
  color: var(--bcolor);

}
.D6_SL_WS_HIconDu {
    display: inline-flex;
    width: 40px;
    height: 40px;
}
.Icon_mail{
  color: black;
  font-size: 36px;
  padding: 10px;
 

  animation: fadeIn 0.3s;
  /* Alansition: opacity 0.4s; */
   animation: Top 0.5s alternate ease infinite;
/*   margin-left: 1300px;  */
cursor: pointer;
 }

 @keyframes Top {
   from{
       transform: translateY(2px);
   }
   to{
       transform: translateY(-1px);
   }
}


.D6_SL_WS_Grid2_Div_H1Du  {
    font-size: 30px !important;
    font-weight: 300;
    color: var(--bcolor);
    line-height: 40px;
    padding-left: 20px;
    padding-bottom: 10px;
    display: inline-flex;
    text-align: left;
}
.D6_SL_WS_Grid2_Div_H1Du_list  {
  font-size: 26px !important;
  font-weight: 300;
  color: var(--bcolor);
  line-height: 40px;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  display: inline-flex;
  text-align: left;
}
.D6_ADS_G1_DivPAl_B  {
  font-weight: bold;
  padding-left: 30px !important;
  font-size: 1rem !important;
  color: var(--bcolor);
}
.D6_ADS_ContDu  {
    margin-top: 50px;
    
    
  }
 .D6_ADS_G1_DivDu {
    height: auto;
    max-width: 600px;
    min-width: 300px;
    /* padding: 0 30px; */
    margin-bottom: 10px;
  
  }


  .D6_ADS_G1_DivHDu  {
    padding: 0px 10px;
  
  }
  .D6_ADS_G1_DivPDu  {
    padding: 10px 10px
  
  }
  .D6_ADS_G1_DivPAl_B  {
    padding: 10px 10px;
  list-style-type:circle;
  font-weight: 500;
  }
  .D6_ADS_G1_IDivDu  {
    max-width: 450px;
    margin: 0px auto;
    height: 300px;
    min-width: 300px;
  
  }
  .ulDu{
    padding-left: 40px;
    padding-bottom: 30px;
    padding-top: 20px;
  }
  .D6_ADS_ImgDu  {
    width: 450px;
    height: 350px;
    width: 100%;
    border-radius: 10px;
  
  };
  

  @media (max-width: 600px) {
    

  .Grid_Types_h{
    padding-top: 20px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 20px !important;
    color: var(--bcolor);
  }
  .li_dual{
    padding-top: 20px;
    font-size: 18px;
    padding-bottom: 10px;
    color: var(--bcolor);
  
  }
}