/* @import "../../MainCSS.css"; */

.W_T_cont{
    max-width: 100% !important;
}
.Dec6_Services_GPC_MainBlock_bg {
    width: 100%;
    height: 650px;
    background-color: #f7f7f7;
  /*  background: transparent; */
    margin-top: 100px;
    
    font-family: var(--fontfamily);
}

.Dec6_Services_GPC_MainBlock {
    display: grid;
    width: 80%;
    align-items: center;
    margin: auto;
    grid-template-rows: 250px 250px;
    grid-template-columns: repeat(3,1fr);
    row-gap: 30px;
}

.Dec6_Services_GPC_Grid {
    padding-bottom: 40px;
    /* margin-top: 60px; */
}


.Dec6_Services_GPC_Cont1 {
    padding-top: 40px;
} 
.Dec6_Services_GPC_Cont1_Paper {
    max-width: 300px;
    width: 100%;
    height: 200px;
    margin: 0px auto;
 /*   padding-top: 100px;*/
    
}

.Dec6_Services_GPC_Cont1_Paper_Box_H {
    padding-top: 70px; 
    font-size: 1.3rem;
    /* color: #cc0000 !important; */

}
.Dec6_Services_GPC_Cont1_Content {
    transform: scale(0);
}
.Dec6_Services_GPC_Cont1_Paper_Box_SubH {
    font-size: 0.9rem;
    font-weight: lighter;
    padding-bottom: 5px;
}

.Dec6_Services_GPC_Cont1_Paper_Box {
    text-align: center;
    padding-top: 10px;
    color: var(--ocolor) !important;
    transition: transform 2s;
    border-radius: 5%;
}

.Dec6_Services_GPC_Cont1_Paper_Box:hover {
    transform: scale(1.2);
    background-color: var(--ocolor);
    color:white !important;
    width: 300px;
    height: 200px;
    cursor: pointer;
 /*   opacity: 0.2; */
}
.Dec6_Services_GPC_Cont1_Paper_Box.Dec6_Services_GPC_Cont1_Paper_Box_SubH :hover{
    color:white !important;

}

.Dec6_Services_GPC_Cont1_Paper_Box:hover .Dec6_Services_GPC_Cont1_Content{
    transform: scale(1);
    padding-top: 15px;
 /*   opacity: 0.2; */
}

.Dec6_Services_GPC_Cont1_Paper_Box:hover .Dec6_Services_GPC_Cont1_Paper_Box_H{
    /* padding-top: 50px; */
}

