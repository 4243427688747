@import "../../../MainCSS.css";
/*
.Dec6_STTop_Block {
    position: fixed;
    bottom: 12px;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: var(--redcolor);
    color: var(--whitecolor);
    z-index: 1;
}
.Dec6_STTop_Icon {
    margin-top: 5px;
    margin-left: 6px;
    color: white;
    font-size: 27px;
    animation: scrollTop 0.5s alternate ease infinite;
}
@keyframes scrollTop {
    from{
        transform: translateY(2px);
    }
    to{
        transform: translateY(-1px);
    }
}
*/

.Dec6_ST_Div1 {
  /* display: flex;
  justify-content: flex-end; */
  margin: 0px auto;  
  margin-left: 10px;
}
  
  .phoneTop {
    position: fixed; 
    width: 100%;
    width: 40px;
    top: 125px;
    align-items: center;
    color: var(--ycolor);
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
   /* transition: opacity 0.4s; */
    animation: Top 0.5s alternate ease infinite;
 /*   margin-left: 1300px;  */
 /* padding-left: 30px; */
  }
  .phoneTopT {
    position: fixed; 
    width: 100px;
    /* width: 40px; */
    top: 125px;
    align-items: center;
    color: var(--ycolor);
    height: 20px;
    font-size: 22px;
    font-weight: bold;
    justify-content: center;
    z-index: 1000;
    padding-left: 50px;
        cursor: pointer;
    animation: fadeIn 0.3s;
   /* transition: opacity 0.4s; */
    animation: Top 0.5s alternate ease infinite;
 /*   margin-left: 1300px;  */
  }
  .phoneTopM {
    position: fixed; 
    width: 40px;
    top: 150px;
    align-items: center;
    color: var(--ycolor);
    font-size: 17px;
    font-weight: bold;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
   /* transition: opacity 0.4s; */
    animation: Top 0.5s alternate ease infinite;
    padding-left: 45px;
    /* padding-right: 127px; */

 /*   margin-left: 1300px;  */
  }
  @keyframes Top {
    from{
        transform: translateY(2px);
    }
    to{
        transform: translateY(-1px);
    }
}
  
  .scrollTop:hover{
    color: var(--bcolor);
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  @media (max-width: 500px) {
    .phoneTop {
   
      top: 20px;
      left: 110px;
      color: var(--wcolor);
     /* transition: opacity 0.4s; */
      animation: Top 0.5s alternate ease infinite;
   /*   margin-left: 1300px;  */
   /* padding-left: 30px; */
    }
    .phoneTopT {
     
      top: 22px;
      left: 110px;
      color: var(--wcolor);
      font-size: 16px;
    font-weight: normal;
     /* transition: opacity 0.4s; */
   /*   margin-left: 1300px;  */
    }
    .phoneTopM {
     
      top: 42px;
      left: 110px;
      color: var(--wcolor);
      font-size: 16px;
    font-weight: normal;
    }
  }