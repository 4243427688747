.Inpatient_below_img1{
    /* padding:0px 0 50px; */
    width:100%;
    /* margin-top: 50px; */
    max-width: 100%;
    text-align: center;    
    z-index: 2;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.8)) ;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.Inpatient_BG{
    width:100%;
    /* margin-top: 50px; */
    max-width: 100%;
    text-align: center;    
    z-index: 2;
    background:  url("../../../../assets/Images/AM_BG1.jpg") ;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    .Inpatient_BG{
        width:100%;
        /* margin-top: 50px; */
        max-width: 100%;
        text-align: center;    
        z-index: 2;
        background:  url("../../../../assets/Images/AM_BG1.jpg") ;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }
}