@import "../../../MainCSS.css";


.D6_menu1{
  opacity: 1;
  transform: none;
  transition: opacity 339ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 226ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 80px!important;
  left: 1025px !important;
  transform-origin: 0px 0px;
}

.menuService{
  font-size: 18px;
  font-weight: 440;
  cursor: pointer;
}
.menuService .active1{
  color: white !important; ;
  border-bottom: 2px solid white !important;

}


.menuService:hover{
  color:var(--ycolor) ;
  /* border-bottom: 2px solid white; */
  /* margin-bottom: 2px; */
  }
.services_menu{
  left: -67px !important;
}
   
.services_menu2{
  left: -134px !important;
}
  

   
  
   
  
   
  
   
  
   
  
  
  
   
  







