/* @import '../../../MainCSS.css'; */


.spec-container {
    /* background-color:var(--ocolor); */
    /* padding:0px 0 50px; */
    width:100%;
    /* margin-top: 50px; */
    max-width: 100%;
    text-align: center;    
    z-index: 2;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.8)) ;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-bottom: 30px
}

.D6_TS_GH {
    font-size: 45px;
    font-weight: 350;
color:silver;
    text-align: center;
  
}
.D6_TS_GDiv_H  {
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;

  
   }
.D6_TS_GDiv_Img_H  {
       width: 150px;
       height: 150px;
       margin: 0;
       border-radius: 50%;
       border: 2px dotted var(--ycolor);
       overflow: hidden;
       padding:5px;
       display: flex;
  
   };

.D6_TS_GDiv_Text_H  {
    width: 300px;
    margin: 0 0 0 30px;
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
   
};
.D6_TS_GDiv_TH_H  {
    font-size: 18px;
    font-weight: 700;
    margin: 0px 0 0px;
    display: flex;

};

.D6_TS_GDiv_TP_H  {
    font-size:16px;
    font-weight: 600;
    display: flex;
  
};
.D6_TS_GDiv_TP_Details_H {
    font-size:16px;
    font-weight: 400;
    display: flex;
    margin-top: 10px;
 
};
.D6_TS_Cont{
    color:var(--bcolor);
    align-items: center;
    text-align: center;
    margin: 0px auto;
    margin-top: 20px   
}
.D6_TS_GDiv{
    margin-top: 20px

}

.D6_TS_GDiv_Img{
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px dotted #36444e;
    overflow: hidden;
    padding:5px;
}
.D6_TS_GDiv_ImgS{
    width: 100%;
    border-radius: 50%
}
.D6_TS_GDiv_Text{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px

}.D6_TS_GDiv_TH{
    font-size: 18px;
    font-weight: 600;
    margin: 20px auto 5px;
    color:silver;


}
.D6_TS_GDiv_TP{
    font-size:16px;
    font-weight: 400;
    color:silver;
    padding-top: 5px;
    text-align: justify;


}