:root{
    --gcolor: #36444e;
    /* --gcolor: #000; */

    /* --ycolor: var(--ocolor); */
    /* --ycolor: #ff4500; */
    --ycolor: #a3cc3a;
    --bcolor: #2f2f2f;
    --wcolor: #ffffff;
    --g1color: #495963;
    /* --ocolor: #ff4500; */
    --ocolor: #a3cc3a;
}


body, .MuiTypography-body1, .MuiTab-root{
    font-family: 'Open Sans', sans-serif !important;
}


h3 {
    
    font-weight: 700;
    /* color: var(--bcolor); */
    font-size: 32px;
  }
p {
    font-weight: 500;
    color: var(--bcolor);
    font-size: 19px;
}



.MuiTab-textColorInherit {
    color: var(--wcolor) !important;
    font-size: 18px !important;
}

/* Navlinks Active */
.D6_SL_Navbar_List .active {
    color:white ;
    border-bottom: 2px solid white;
    padding: 8px 0px;
 }

/* Schedule Appointment Button */
.D6_DS_BG_Button {
    font-size: 23px;
    color: var(--bcolor);
    padding: 10px 10px;
    background-color: var(--ycolor);
    border-radius: 5px;
    border: 1px solid var(--ycolor);
    cursor: pointer;
    width: 260px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -130px;
}
.MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.MuiIconButton-label{
    display: flex !important;
    justify-content: flex-end !important;
}
/* .MuiSvgIcon-root {
    padding-top: 5px !important;
} */
.MuiIconButton-root{
    padding: 0px !important;
    padding-right: 10px !important;
    margin-bottom: -28px !important;
}

/* Tabs */
.PrivateTabIndicator-colorSecondary-14 {
    background-color: var(--gcolor) !important;
}

@media (max-width: 600px) {
    .MuiBox-root-17 {
        padding: 10px !important;
    }
    .MuiTab-textColorInherit {
        font-size: 14px !important;
    }
    .D6_DS_BG_Button {
        font-size: 16px;
        width:200px;
        margin-left: -100px;
        bottom:-50px;
    }
}