.Gallery_img_li{
    height: 300px !important;
}

@media (max-width:500px) {
    .Gallery_ul{
        padding-left: 2px !important;
        grid-template-columns: repeat(2, 1fr) !important;
    }    
    .Gallery_img_li{
        height: 200px !important;
    }
}
