@import "../../../MainCSS.css";



.D6_DS_BG_Div {
  
    width: 100%;
    background-size: 100%;
    align-items: center;
    text-align: center;
}
.img{
      width:100%;
    }
  
  .D6_SL_WS_Grid2_HDivBlog6  {
    width: 100%;
    border-bottom: 2px solid var(--ocolor);
    margin-bottom: 10px;
    display: flex;
}
.D6_SL_WS_HIconBlog6 {
    display: inline-flex;
    width: 40px;
    height: 40px;
}
.Icon_mail{
  color: black;
  font-size: 36px;
  padding: 10px;
 

  animation: fadeIn 0.3s;
  /* Blog6ansition: opacity 0.4s; */
   animation: Top 0.5s alternate ease infinite;
/*   margin-left: 1300px;  */
cursor: pointer;
 }

 @keyframes Top {
   from{
       transform: translateY(2px);
   }
   to{
       transform: translateY(-1px);
   }
}


.D6_SL_WS_Grid2_Div_H1Blog6  {
    font-size: 30px !important;
    font-weight: 300;
    color: var(--bcolor);
    line-height: 40px;
    padding-left: 20px;
    padding-bottom: 10px;
    display: inline-flex;
    text-align: left;
}
.D6_SL_WS_Grid2_Div_H1Blog6_list  {
  font-size: 26px !important;
  font-weight: 300;
  color: var(--bcolor);
  line-height: 40px;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  display: inline-flex;
  text-align: left;
}
.D6_ADS_G1_DivPBlog6_B  {
  color: var(--bcolor);
  font-weight: bold;
}
.D6_ADS_ContBlog6  {
    margin-top: 50px;
    
    
  }
 .D6_ADS_G1_DivBlog6 {
    height: auto;
    max-width: 600px;
    min-width: 300px;
    padding: 0 30px;
    margin-bottom: 10px;
  
  }


  .D6_ADS_G1_DivHBlog6  {
    padding: 0px 10px;
  
  }
  .D6_ADS_G1_DivPBlog6  {
    padding: 10px 10px
  
  }
  .D6_ADS_G1_DivPBlog6_B  {
    padding: 10px 10px;
  list-style-type:circle;
  font-weight: 500;
  }
  .D6_ADS_G1_IDivBlog6  {
    max-width: 450px;
    margin: 0px auto;
    height: 300px;
    min-width: 300px;
  
  }
  .ulBlog6{
    padding-left: 40px;
    padding-bottom: 30px;
    padding-top: 20px;
  }
  .D6_ADS_ImgBlog6  {
    width: 450px;
    height: 300px;
    width: 100%;
    border-radius: 10px;
  
  };
  