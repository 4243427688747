

.D6_SL_Footer_Items {
  height: 65px;
  background-color: #000;
  color: white;
  /* padding-left: 55px; */
  padding-left: 0px;
  padding-top: 15px;
  text-align: center;
    align-items: center;
    border-top:1px solid var(--bcolor);
}
.D6_SL_Footer_Items_NavLink {
    font-size: 14px;
    text-align: center;
    height: 15px;
}
.D6_SL_Footer_Items_NavLink1 {
    display: inline-block;
    width: 70px;
    border-right: 1px solid var(--whitecolor);
}
.D6_SL_Footer_Items_NavLink2 {
    display: inline-block;
    width: 150px;
    text-align: center;
    border-right: 1px solid var(--whitecolor);
}
.D6_SL_Footer_Items_NavLink3 {
    display: inline-block;
    width: 175px;
}
.D6_SL_Footer_Items a {
    text-decoration: none;
    font-size: 14px;
    color: var(--whitecolor);
}

.D6_SL_Footer_Items_P {
    text-align: center;
    font-size: 14px;
}
.D6_SL_Footer_Items_P_Div {
    margin-top: 15px;
}
.D6_SL_Footer_Items_P {
    color: white;
}

@media (max-width: 410px) {
    .D6_SL_Footer_Items_NavLink1 {
        display: inline-block;
        width: 37px;
    }
    .D6_SL_Footer_Items_NavLink2 {
        display: inline-block;
        width: 77px;
        border-right: 1px solid var(--whitecolor);
    }
    .D6_SL_Footer_Items_NavLink3 {
        display: inline-block;
        width: 105px;
    }
    .D6_SL_Footer_Items a {
        text-decoration: none;
        font-size: 9px;
        color: var(--whitecolor);
    }
    .D6_SL_Footer_Items_P {
        text-align: center;
        font-size: 14px;
    }
}
