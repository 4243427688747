@import "../../../../MainCSS.css";



.D6_DS_BG_Div {
  
    width: 100%;
    background-size: 100%;
    align-items: center;
    text-align: center;
}
.img{
      width:100%;
    }
  
  .D6_SL_WS_Grid2_HDiv_Online  {
    width: 100%;
    border-bottom: 2px solid var(--ocolor);
    margin-bottom: 10px;
    display: flex;
}
.D6_SL_WS_HIcon_Online {
    display: inline-flex;
    width: 40px;
    height: 40px;
}
.D6_SL_WS_Grid2_Div_H1_Online  {
    font-size: 30px !important;
    font-weight: 300;
    color: var(--bcolor);
    line-height: 40px;
    padding-left: 20px;
    padding-bottom: 10px;
    display: inline-flex;
    text-align: left;
}
/* .D6_ADS_G1_DivP_Online_B  {
  font-weight: bold;
} */
.D6_ADS_Cont_Online  {
    margin-top: 50px;
    
    
  }
 .D6_ADS_G1_Div_Online {
    height: auto;
    max-width: 600px;
    min-width: 300px;
    padding: 0 30px;
    margin-bottom: 10px;
  
  }


  .D6_ADS_G1_DivH_Online  {
    padding: 0px 10px;
  
  }
  .D6_ADS_G1_DivP_Online  {
    padding: 10px 10px
  
  }
  .D6_ADS_G1_DivP_Online_B  {
    padding: 10px 10px;
  list-style-type:circle;
  text-align: left;
  }
  .D6_ADS_G1_IDiv_Online  {
    max-width: 450px;
    margin: 0px auto;
    height: 300px;
    min-width: 300px;
  
  }
  .ul_online{
    padding-left: 20px;
  }
  .D6_ADS_Img_Online  {
    width: 450px;
    height: 300px;
    width: 100%;
    border-radius: 10px;
  
  };
  