/* @import "../../MainCSS.css"; */

.W_T_cont{
    max-width: 100% !important;
}


.Dec6_Services_GPC_Grid_Psy {
    padding-bottom: 40px;
    /* margin-top: 60px; */
}


.Dec6_Services_GPC_Cont1_Psy {
    padding-top: 40px;
} 
.Dec6_Services_GPC_Cont1_Psy_Paper {
    max-width: 320px;
    width: 100%;
    height: 130px;
    margin: 0px auto;
 /*   padding-top: 100px;*/
    
}
.Icon_Right{
    color: #cc0000;
    
    font-size: 36px;
    padding-top: 20px;
    animation: fadeIn 0.3s;
    /* transition: opacity 0.4s; */
     animation: Right 0.5s alternate ease infinite;
  /*   margin-left: 1300px;  */
  cursor: pointer;
   }
 
   @keyframes Right {
     from{
         transform: translateX(2px);
     }
     to{
         transform: translateX(4px);
     }
 }

.Dec6_Services_GPC_Cont1_Psy_Paper_Box_H {
    font-size: 1.3rem;
    /* color: #cc0000 !important; */

}
.Dec6_Services_GPC_Cont1_Psy_Content {
    transform: scale(0);
}
.Dec6_Services_GPC_Cont1_Psy_Paper_Box_SubH {
    font-size: 0.9rem;
    font-weight: lighter;
    padding-bottom: 5px;
}
.paragraph_Psynpatient{
    font-size: 0.86rem;
    font-weight: normal;
    padding-bottom: 5px;
    color: black;
    padding-top: 20px;
}

.Dec6_Services_GPC_Cont1_Psy_Paper_Box {
    text-align: center;
    padding-top: 35px;
    color: #cc0000 !important;
    transition: transform 2s;
    border-radius: 5%;
}

.Dec6_Services_GPC_Cont1_Psy_Paper_Box:hover {
    transform: scale(1.2);
    width: 320px;
    height: 135px;
    border-radius: 10px;
 /*   opacity: 0.2; */
}

.paragraph_Psynpatient:hover{
    font-size: 0.8rem;
    font-weight: normal;
    padding-bottom: 5px;
    color: white !important;
}


.Dec6_Services_GPC_Cont1_Psy_Paper_Box:hover .Dec6_Services_GPC_Cont1_Psy_Content{
    transform: scale(1);
    padding-top: 15px;
 /*   opacity: 0.2; */
}

.Dec6_Services_GPC_Cont1_Paper_Box:hover .Dec6_Services_GPC_Cont1_Paper_Box_H{
    /* padding-top: 50px; */
}
@media (max-width: 480px){
    .Dec6_Services_GPC_Cont1_Psy_Paper_Box:hover {
        transform: scale(1.2);
        width: 300px;
        height: 135px;
     /*   opacity: 0.2; */
    }
    .Dec6_Services_GPC_Cont1_Psy_Paper {
        max-width: 300px;
        width: 100%;
        height: 130px;
        margin: 0px auto;
     /*   padding-top: 100px;*/
        
    }
   
}
