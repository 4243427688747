/* @import "../../MainCSS.css"; */

.W_T_cont{
  max-width: 100% !important;
}
.Dec6_Services_GPC_MainBlock_bg {
  width: 100%;
  height: 650px;
  background-color: #f7f7f7;
/*  background: transparent; */
  margin-top: 100px;
  
  font-family: var(--fontfamily);
}

.Dec6_Services_GPC_MainBlock {
  display: grid;
  width: 80%;
  align-items: center;
  margin: auto;
  grid-template-rows: 250px 250px;
  grid-template-columns: repeat(3,1fr);
  row-gap: 30px;
}

.Dec6_Services_GPC_Grid {
  padding-bottom: 40px;
  /* margin-top: 60px; */
}


.Dec6_Services_GPC_Blog {
  padding-top: 40px;
} 

.Dec6_Services_GPC_Blog_Paper1{
  max-width: 350px;
  width: 100%;
  height: 230px;
  margin: 0px auto;
  
  background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('../../assets/Images/AM_Self.jpg');  
  /*   padding-top: 100px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-position: bottom;
}
.Dec6_Services_GPC_Blog_Paper2{
  max-width: 350px;
  width: 100%;
  height: 230px;
  margin: 0px auto;
  
  background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('../../assets/Images/AM_Yoga.jpg');  
  /*   padding-top: 100px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-position: bottom;
}
.Dec6_Services_GPC_Blog_Paper3{
  max-width: 350px;
  width: 100%;
  height: 230px;
  margin: 0px auto;
  
  background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('../../assets/Images/AM_Med.jpg');  
  /*   padding-top: 100px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-position: bottom;
}
.Dec6_Services_GPC_Blog_Paper4{
  max-width: 350px;
  width: 100%;
  height: 230px;
  margin: 0px auto;
  
  background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('../../assets/Images/AM_BG1.jpg');  
  /*   padding-top: 100px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-position: bottom;
}
.Dec6_Services_GPC_Blog_Paper5{
  max-width: 350px;
  width: 100%;
  height: 230px;
  margin: 0px auto;
  
  background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('../../assets/Images/AM_BG2.jpg');  
  /*   padding-top: 100px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-position: bottom;
}
.Dec6_Services_GPC_Blog_Paper6{
  max-width: 350px;
  width: 100%;
  height: 230px;
  margin: 0px auto;
  
  background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('../../assets/Images/AM_Blogs.jpg');  
  /*   padding-top: 100px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-position: bottom;
}

.Dec6_Services_GPC_Blog_Paper_Box_H {
  padding-top: 80px; 
  text-align: left;
  font-size: 1.3rem;
  /* color: #cc0000 !important; */

}
.Dec6_Services_GPC_Blog_Content {
  transform: scale(0);
}
.Dec6_Services_GPC_Blog_Paper_Box_SubH {
  font-size: 0.9rem;
  font-weight: lighter;
  padding-bottom: 5px;
}

.Dec6_Services_GPC_Blog_Paper_Box {
  text-align: center;
  padding-top: 10px;
  padding-left: 10px;

  color: #f7f7f7 !important;
  transition: transform 2s;
  border-radius: 5%;
}
.Blog_cat_p{
  color: #f7f7f7 !important;
  padding-top:5px;
  font-size: 17px !important;
  text-align: left;
}


.Dec6_Services_WCU_Cont_Icon_Blog1{
  color: silver;
  font-size: 36px;
  padding-top: 5px;
  /* Alansition: opacity 0.4s; */
  animation: Top1 0.5s alternate ease infinite;
  /*   margin-left: 1300px;  */
  cursor: pointer;
   }
  
   @keyframes Top1 {
     from{
         transform: translateX(2px);
     }
     to{
         transform: translateX(-1px);
     }
  }

