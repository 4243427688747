@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "../../../MainCSS.css";

.HRSC {
  text-align: center !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 50px auto 0;
  color: #fff;
  /* background: #ffff; */
  padding-bottom: 50px;
/*  height: 100vh;  */
}
.D6_DASAC_RS_Div_H {
  color: var(--bcolor);
  padding-top: 15px;
  font-size: 48px;
  font-weight: 300;
  padding-bottom: 30px;
}

.HRSC_container {
  padding: 22px 0;
  padding: 0 0;
  margin: 0px auto;
  text-align: center ;
  align-items: center;
  justify-content: center;
}
.RS_Div_BH {
  margin-left: -190px;
}
.RS_Div_AH {
  margin-top: -21px;
  margin-left: 190px;
}

@media (max-width: 1040px) {
  .RS_Div_BH {
    margin-left: -150px;
  }
  .RS_Div_AH {
    margin-top: -21px;
    margin-left: 150px;
  }
}


@media (max-width: 820px) {
  .RS_Div_BH {
    margin-left: -190px;
  }
  .RS_Div_AH {
    margin-top: -21px;
    margin-left: 190px;
  }
}

@media (max-width: 890px) {
  /*.HRSC {
    margin-top: -50px;
  }*/
}
@media (max-width: 490px) {
  .RS_Div_BH {
    margin-left: -150px;
  }
  .RS_Div_AH {
    margin-top: -21px;
    margin-left: 150px;
  }
}

@media (max-width: 768px) {
  .D6_DASAC_RS_Div_H {
    padding-top: -10px;
  }
}