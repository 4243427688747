@import "../../../MainCSS.css";

.Form_Div_New {
    width: 400px;
    height: 250px;
    height: auto;
    padding-top: 5px;
    padding-bottom: 15px;
}
.Form_Div_New .MuiInput-underline:before {
    border-bottom: 1px solid white !important;
}
.Form_Div_New .MuiInput-underline.Mui-error:after {
    border-bottom: 1px solid var(--ycolor) !important;
}
.Form_Div_New .MuiInput-underline:after {
    border-bottom: 1px solid white !important;
}


.TF_div {
    height: 50px;
    /* margin-top: 10px; */
  }
  .TF_Label {
    font-size: 15px;
    font-weight: 400;
  }
.Form_Div_New .MuiInputBase-input {
    color: white !important;
    padding: 3px !important;
    /* font-size: 14px !important; */
    font-weight:normal !important;
    width: fit-content;
    /* border-bottom: 1px solid var(--wcolor) !important; */
    /* padding-bottom: 6px !important; */
}
.Form_Div_New .MuiOutlined-Input-root {
    border-color: white !important;
}
.Form_Div_New .MuiFormLabel-root {
    color: white  !important;
    font-size:15px !important;
    font-weight:normal !important;
}
.Form_Div_New .MuiButton-containedPrimary {
    font-size: 22px !important;
 /*   width: 120px !important; */
    height: 30px !important; 
    border: 1px solid var(--wcolor) !important;
    border-radius: 4px !important;
    margin-top: 5px !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    color: var(--wcolor) !important;
    background-color: var(--bcolor) !important;
}
.Form_Div_New .MuiButton-contained.Mui-disabled {
    font-size: 14px !important;
}
.Form_Div_New .MuiButton-containedPrimary:hover {
    background-color: var(--ycolor) !important;
    color: var(--gcolor) !important;
    border: 1px solid var(--gcolor) !important;
}

.Form_Div_New .PNDiv2 {
    /* height: 50px; */
    margin-top: 10px;
  }

  .Form_Div_New .PN_Label2 {
      font-size: 16px;
      padding-bottom: 10px;
  }
  .Form_Div_New .PN_Input2 {
    height: 30px;
    background-color: var(--bcolor);
    color: black!important;
    margin-bottom: 5px;
    border-radius: 4px;
  }
  .Form_Div_New .PN_Input21 {
    /* height: 30px; */
    background-color: var(--bcolor);
    color: black!important;
    margin-bottom: 5px;
    border-radius: 4px;
  }
  
 .Form_Div_New .PNDiv2 .react-tel-input {
    font-size: 16px !important;
  }
  
 .Form_Div_New .PNDiv2 .react-tel-input .form-control {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
    height: 28px !important;
    position: relative;
    letter-spacing: 1px;
    border-radius: 5px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 50% !important;
    font-size: 13px !important;
    color: white !important;
    background-color:var(--bcolor) !important;
    border:1px solid white !important;
  }
  .Form_Div_New .TF_div .PN_Input2 .MuiFormHelperText-root{
      font-size: 10px;
      margin-left: 2px;
      margin-top: 0px;

  }