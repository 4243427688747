@import "../../../MainCSS.css";



.D6_DS_BG_Div {
  
    width: 100%;
    background-size: 100%;
    align-items: center;
    text-align: center;
}
.img{
      width:100%;
    }
.D6_Below_img{
background: #f7f7f7;
padding-top: 30px;
padding-bottom: 30px;
    }
.D6_ADS_Grid0{
  width: 100%;
  height: 600px;
  /* background-image: url('../Images/Indus.jpg'); */
 /* background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('../../../assets/Images/const2.jpg');   */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: right;
  background-position: bottom;
    }
  
  .D6_SL_WS_Grid2_HDiv  {
    width: 100%;
    border-bottom: 2px solid var(--ycolor);
    margin-bottom: 10px;
    display: flex;
}
.D6_SL_WS_HIcon {
    display: inline-flex;
    width: 40px;
    height: 40px;
}
.D6_SL_WS_Grid2_Div_H1  {
    font-size: 30px !important;
    font-weight: 300;
    color: var(--bcolor);
    line-height: 40px;
    padding-left: 20px;
    padding-bottom: 10px;
    display: inline-flex;
    text-align: left;
}
.D6_ADS_Cont_DASAC  {
    background: #f7f7f7;
    
    
  }
 .D6_ADS_G1_Div  {
    height: auto;
    max-width: 600px;
    min-width: 300px;
    padding: 0 30px;
    margin-bottom: 10px;
  
  }


  .D6_ADS_G1_DivH  {
    padding: 0px 10px;
  
  }
  .D6_ADS_G1_DivP  {
    padding: 10px 10px;
    text-align: justify;
  
  }
  .D6_ADS_G1_IDiv  {
    max-width: 450px;
    margin: 0px auto;
    height: 300px;
    min-width: 300px;
  
  }

  .D6_ADS_Img  {
    width: 450px;
    height: 300px;
    width: 100%;
    border-radius: 10px;
  
  };
  @media (max-width:500px) {
    .D6_ADS_Grid0{
      height: 500px;
    }
    
  }
  