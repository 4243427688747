.D6_DASAC_SA_Div .MuiFormControl-root {
    width: 150px !important;
}
.D6_DASAC_Form .MuiButton-containedPrimary {
    font-size: 24px !important;
 /*   width: 120px !important; */
    height: 40px !important; 
    border: 1px solid var(--ycolor) !important;
    border-radius: 4px !important;
    margin-top: 15px !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    color: var(--ycolor) !important;
    background-color: var(--gcolor) !important;
}
.D6_DASAC_Form .MuiButton-contained.Mui-disabled {
    font-size: 0.8rem !important;
}
.D6_DASAC_Form .MuiButton-containedPrimary:hover {
    background-color: var(--ycolor) !important;
    color: var(--gcolor) !important;
    border: 1px solid var(--gcolor) !important;
}