
.D6_SL_SC_Slider_Div .slick-next {
    right: 0px !important;
}
.D6_SL_SC_Slider_Div .slick-slide img{
    width: 100% !important;
    height: 650px !important;

}
.D6_SL_SC_Slider_Div .slick-next:before {
    content: '' !important;
}
.D6_SL_SC_Slider_Div .slick-dots {
    bottom: 0px !important;
}
.D6_SL_SC_Slider_Div .slick-dots li{
    width:50px;
}
.D6_SL_SL_Button {
    color: var(--wcolor);
    padding: 5px 20px;
    margin-top: 10px;
    font-size: 22px;
    border: 1px solid var(--ycolor);
    background-color: var(--ycolor);
    cursor: pointer;
    border-radius: 5px;
}
.D6_SL_SC_Slider_Div .slick-dots li button:before {
    width: 50px !important;
    height: 5px !important;
    color: #ffffff !important;
    background: var(--ycolor) !important;
    content: '' !important;
    border-radius: 5px;
}
.D6_SC_SliderDiv {
    margin-left: 100px;
    width: 440px;
    height: 150px;
    position: absolute;
    bottom: 80px;
}
.D6_SC_SliderDivH1 {
    font-size: 41px;
    color: white;
    padding-bottom: 10px;
}
.D6_SC_SliderDivH2 {
    font-size: 21px;
    font-weight: normal;
    color: white;
}
.D6_SL_SC_Slider_Div .prevArrow {
    position: absolute;
    top: 40%;
    left: 20px;
    z-index: 100;
    cursor: pointer;
    font-size: 42px;
    color: white;
  }
  
  .D6_SL_SC_Slider_Div .nextArrow {
    position: absolute;
    top: 40%;
    right: 20px;
    z-index: 100;
    cursor: pointer;
    font-size: 42px;
    color: white;
  }


  /* .D6_SL_Img1_div{
    margin-top: 50px;
  } */
  .img1{
    width:100%;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.8)) !important;


  }
  .Dialog_CButton.MuiPaper-root{
    margin:0px !important;
  }
  .Dialog_CButton.MuiDialogContent-root{
    width: 328px !important;
    padding:25px 30px 0px  !important;
          text-align: center!important;
  }
  .MuiDialogContent-root{
    width: 328px !important;
    padding:25px 30px 0px  !important;
          text-align: center!important;
  }
  .Dialog_CButton.MuiPaper-rounded{
    border-radius: 22px!important;
  }
  .Dialog_CButton.MuiBackdrop-root{
    background: rgba(0,0,0,0.44)!important;
  
  }
  .modal-title{
    margin-bottom: 0!important;
        font-weight: bold!important;
        text-align: left!important;
        font-size: 22px!important;
        line-height: 24px!important;
  }
  
  .icon-close-btn{
    width: 33px!important;
        height: 33px!important;
        position: absolute!important;
        top: 0px!important;
        left: 345px!important;
        padding: 0!important;
        background-color: transparent!important;
  }
  .icon-close-btn.MuiIconButton-label{
    display: block!important;
  
  }
  .icon-close-btn:hover{
    background-color: transparent!important;
  
  }
  



@media (max-width: 768px) {
    .D6_SL_SC_Slider_Div .slick-slide img{
        width: 100% !important;
        height: 400px !important;
    }
    .D6_SC_SliderDiv {
        margin-left:20px;
        bottom: 100px;
    }
    .D6_SL_SC_Slider_Div .prevArrow {
        font-size: 24px;
      }
      .D6_SL_SC_Slider_Div .nextArrow {
        font-size: 24px;
      }
      .D6_SL_SL_Button {
        padding: 5px 10px;
        font-size: 18px;
      }
}
@media (max-width: 600px) {
    .D6_SL_SC_Slider_Div .slick-slide img{
        width: 100% !important;
        /* height: 300px !important; */
        height: 400px !important;
    }
    .D6_SC_SliderDiv {
        margin-left:20px;
        bottom: 40px;
    }
    .D6_SC_SliderDivH1 {
        font-size: 24px;
        margin-top:20px;
        width: 150px;
    }
    .D6_SC_SliderDivH2 {
        font-size: 16px;
        font-weight: normal;
        color: white;
    }
    .D6_SL_SL_Button {
        padding: 5px 5px;
        font-size: 16px;
    }
    .D6_SL_SC_Slider_Div .prevArrow {
        display:none;
      }
      .D6_SL_SC_Slider_Div .nextArrow {
        display:none;
      }
      .icon-close-btn{
       left: 277px !important;
      }
      .MuiDialogContent-root{
        padding:25px 11px 0px  !important;
      }

}
@media (max-width: 500px) {
    .D6_SC_SliderDiv {
        bottom: 40px;
    }
    .Dialog_CButton.MuiDialogContent-root{
      width: 328px !important;
      padding:25px 10px 0px  !important;
            text-align: center!important;
    }
    .MuiDialogContent-root{
      width: 290px !important;
      padding:25px 10px 0px  !important;
            text-align: center!important;
    }
}
