@import "../../../MainCSS.css";

* {
    margin: 0;
    padding: 0;
}
.D6_SL_Navbar_Items {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    background-color: rgb(0,0,0,0.6);
    
    color: white;
   position: fixed; 
    top: 0;
    z-index: 101;
    margin-top: 50px;
}
.D6_SL_Navbar_Items1 {
    width: 100%;
    height: 50px;
    background-color: var(--ycolor);
  
    
   
   position: fixed; 
    z-index: 101;
}

.D6_SL_Navbar_Items1_Image {
    /* width: 200px; */
    height: 60px;
    padding: 10px 5px;
    padding-left: 40px;
    text-decoration: none;
    color: white;
}
.select{
    display: inline-flex !important;
}
/* .D6_SL_Navbar_Items.active {
    position: fixed;
  } */

  .text_cont{
    padding-top: 15px;
    font-size: 20px;
    font-weight: 900;
    color: white;
    /* cursor: pointer; */
  }
  .tel_cont{
    padding-top: 15px;
    font-weight: 900;
    color: white;
    cursor: pointer;
    text-align: right;
  }
  .tel2_cont{
    padding-top: 15px;
    font-weight: 900;
    color: white;
    cursor: pointer;
    text-align: left;
  }
@media (max-width: 990px) {
    /* .D6_SL_Navbar_Items1 {
        width: 100%;
        height: 80px;
    } */
    .select{
        display: inline-flex !important;
    }
    .D6_SL_Navbar_Items1_Image {
        display: flex;
        justify-content: flex-start;
        /* width: 250px; */
        /* padding-top: 10px; */
        padding-left: 0px;
        height: 80px;
    }
    /* .D6_SL_Navbar_Items {
        height: 80px;
    } */
  }

@media (max-width: 768px) {
    .D6_SL_Navbar_Items {
        width: 100%;
        height: 80px;
        margin-top: 100px;
    }
    .logo_n{
  margin-left: 5px;
  margin-top: 10px;
    }
    .select{
        display: inline-flex !important;
    }
    .D6_SL_Navbar_Items1_Image {
        /* display: flex;
        justify-content: flex-start;
        padding-top: 10px;
        padding-left: 20px;
        height: 80px; */
        height: 65px;
    /* padding: 10px 5px; */
    /* padding-left: 14px; */
    padding-top: 0px;
    text-decoration: none;
    color: white;
    }
    .tel_cont{
        padding-top: 15px;
        font-weight: 900;
        color: white;
        cursor: pointer;
        text-align: center;
      }
      .tel2_cont{
        padding-top: 15px;
        font-weight: 900;
        color: white;
        cursor: pointer;
        text-align: center;
      }
      .D6_SL_Navbar_Items1 {
        width: 100%;
        height: 100px;
        background-color: var(--ycolor);
      
        
       
       position: fixed; 
        z-index: 101;
    }
  }